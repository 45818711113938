// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".styles-module__root--2_HbB{display:flex;justify-content:center;pointer-events:none;z-index:22}.styles-module__notificationWrapper--bn5LF{padding-top:10px;display:flex;flex-direction:column;gap:10px;pointer-events:auto}.styles-module__notificationRow--14DTA{display:flex;justify-content:center}", ""]);
// Exports
exports.locals = {
	"root": "styles-module__root--2_HbB",
	"notificationWrapper": "styles-module__notificationWrapper--bn5LF",
	"notificationRow": "styles-module__notificationRow--14DTA"
};
module.exports = exports;
