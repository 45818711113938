const RESTORE_PASSWORD_EVENTS = {
  RESTORE_FORGOT_PASSWORD_CLICK: 'RESTORE_FORGOT_PASSWORD_CLICK',
  RESTORE_PHONE_STEP_NEXT_CLICK: 'RESTORE_PHONE_STEP_NEXT_CLICK',
  RESTORE_ERROR_PROVIDER: 'RESTORE_ERROR_PROVIDER',
  RESTORE_ERROR_NOT_FOUND: 'RESTORE_ERROR_NOT_FOUND',
  RESTORE_ERROR_PHONE_FORMAT: 'RESTORE_ERROR_PHONE_FORMAT',
  RESTORE_CODE_STEP_NEXT_CLICK: 'RESTORE_CODE_STEP_NEXT_CLICK',
  RESTORE_PASSWORD_STEP_SUBMIT_CLICK: 'RESTORE_PASSWORD_STEP_SUBMIT_CLICK',
  RESTORE_ERROR_PASSWORD_FORMAT: 'RESTORE_ERROR_PASSWORD_FORMAT',
  RESTORE_ERROR_PASSWORD_INVALID_SYMBOLS: 'RESTORE_ERROR_PASSWORD_INVALID_SYMBOLS',
  RESTORE_PASSWORD_SUCCESS: 'RESTORE_PASSWORD_SUCCESS',
  RESTORE_PASSWORD_TO_LOGIN_CLICK: 'RESTORE_PASSWORD_TO_LOGIN_CLICK'
};

export {RESTORE_PASSWORD_EVENTS};
