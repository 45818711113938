const LOGIN_EVENTS = {
  LOGIN_WITH_GOOGLE_CLICK: 'LOGIN_WITH_GOOGLE_CLICK',
  LOGIN_WITH_PHONE_CLICK: 'LOGIN_WITH_PHONE_CLICK',
  LOGIN_WITH_PHONE_NEXT_CLICK: 'LOGIN_WITH_PHONE_NEXT_CLICK',
  LOGIN_WITH_PHONE_ERROR_PROVIDER: 'LOGIN_WITH_PHONE_ERROR_PROVIDER',
  LOGIN_WITH_PHONE_ERROR_NOT_FOUND: 'LOGIN_WITH_PHONE_ERROR_NOT_FOUND',
  LOGIN_WITH_PHONE_ERROR_FORMAT: 'LOGIN_WITH_PHONE_ERROR_FORMAT',
  LOGIN_WITH_PHONE_PASSWORD_NEXT_CLICK: 'LOGIN_WITH_PHONE_PASSWORD_NEXT_CLICK',
  LOGIN_WITH_PHONE_ERROR_PASSWORD_WRONG: 'LOGIN_WITH_PHONE_ERROR_PASSWORD_WRONG',
  LOGIN_WITH_PHONE_SUCCESS: 'LOGIN_WITH_PHONE_SUCCESS',
  LOGIN_WITH_GOOGLE_BUTTON_OBSERVE: 'LOGIN_WITH_GOOGLE_BUTTON_OBSERVE'
};

export {LOGIN_EVENTS};
