import {NOTIFICATION_EVENTS} from '../../../services/analytics/pub-sub/constants';

const NOTIFICATION_DURATION = 5000;
const showNotification = notificationProps =>
  window.notificationCenter.publish(NOTIFICATION_EVENTS.SHOW, notificationProps);
const hideNotification = notificationId =>
  window.notificationCenter.publish(NOTIFICATION_EVENTS.HIDE, notificationId);

export {
  showNotification,
  hideNotification,
  NOTIFICATION_DURATION
};
