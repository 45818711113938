const PREGNANT_WIDGET_EVENTS = {
  PREGNANT_WIDGET_SHOW: 'PREGNANT_WIDGET_SHOW',
  PREGNANT_DEFAULT_INIT_SUBSCRIBE_CLICK: 'PREGNANT_DEFAULT_INIT_SUBSCRIBE_CLICK',
  PREGNANT_DEFAULT_CONTINUE_CLICK: 'PREGNANT_DEFAULT_CONTINUE_CLICK',
  PREGNANT_DEFAULT_SUCCESS: 'PREGNANT_DEFAULT_SUCCESS',
  PREGNANT_DEFAULT_ERROR_REQUIRED: 'PREGNANT_DEFAULT_ERROR_REQUIRED',
  PREGNANT_DEFAULT_ERROR_VALIDATION: 'PREGNANT_DEFAULT_ERROR_VALIDATION',
  PREGNANT_CONFIRM_CLICK: 'PREGNANT_CONFIRM_CLICK',
  PREGNANT_GOOGLE_LOGIN_SUCCESS: 'PREGNANT_GOOGLE_LOGIN_SUCCESS',
  PREGNANT_GOOGLE_CONTINUE_CLICK: 'PREGNANT_GOOGLE_CONTINUE_CLICK',
  PREGNANT_GOOGLE_LINK_ERROR: 'PREGNANT_GOOGLE_LINK_ERROR',
  PREGNANT_GOOGLE_SUCCESS: 'PREGNANT_GOOGLE_SUCCESS'
};

export {PREGNANT_WIDGET_EVENTS};
