import React from 'react';
import {render} from 'react-dom';

import NotificationCenter from './website-ui/components/notification-center';

const rootElement = document.getElementById('notification-center');

if (rootElement) {
  render(
    <NotificationCenter />,
    rootElement
  );
}
