import {ADFOX_EVENTS} from './events/adfox';
import {BOOKMARKS_EVENTS} from './events/bookmarks';
import {COMMENT_AUTH_OVERLAY} from './events/comment-auth-overlay';
import {CUSTOM_EMBED_RELATED_CONTENT} from './events/custom-embed-related-content';
import {DIRECT_LINKS_EVENTS} from './events/direct-links';
import {LOGIN_EVENTS} from './events/login';
import {MOBILE_AUTH_EVENTS} from './events/mobile-auth';
import {ONE_SIGNAL_EMBED} from './events/one-signal-embed';
import {PREGNANT_WIDGET_EVENTS} from './events/pregnant-widget';
import {PROFILE_EVENTS} from './events/profile';
import {REQUEST_ERROR_EVENTS} from './events/request-error';
import {RESTORE_PASSWORD_EVENTS} from './events/restore-password';
import {SIGN_UP_EVENTS} from './events/sign-up';

const ANALYTIC_EVENTS = {
  PAGEVIEW: 'pageview',
  RESIZE: 'resize',
  PAGEVIEW_INFINITE: 'pageview_infinite',
  COMMERCIAL_MENU_IMPRESSION: 'commercial_menu_impression',
  COMMERCIAL_MENU_CLICK: 'commercial_menu_click',
  SUBSCRIPTION_CHANGE: 'subscription_change',
  LOGIN: 'login',
  REGISTRATION: 'registration',
  RECOVER: 'recover',
  SET_USER_TYPE: 'set_user_type',
  COMMENTS: 'comments',
  REACTIONS: 'reactions',
  LOGOUT_BY_USER: 'logout_by_user',
  LOGOUT_BY_SYSTEM: 'logout_by_system',
  REFRESH_TOKEN_SUCCESS: 'refresh_token_success',
  REFRESH_TOKEN_ERROR: 'refresh_token_error',
  SHARE: 'share',
  RECOMMENDATION: 'recommendation',
  UFC_WIDGET_CLICK: 'ufc_widget_click',
  UFC_WIDGET_IMPRESSION: 'ufc_widget_impression',
  PM_WIDGET_CLICK: 'pm_widget_click',
  PM_WIDGET_IMPRESSION: 'pm_widget_impression',
  ONEXBET_WIDGET_CLICK: 'onexbet_widget_click',
  ONEXBET_WIDGET_IMPRESSION: 'onexbet_widget_impression',
  SET_MATCH_LABEL: 'set_match_label',
  ARTICLE_SCROLL: 'article_scroll',
  VISIT: 'visit',
  ARTICLE_REACTIONS_CHANGE: 'article_reactions_change',
  ARTICLE_REACTIONS_TAP: 'article_reactions_tap',
  ARTICLE_REACTIONS_OBSERVE: 'article_reactions_observe',
  ARTICLE_REACTIONS_TAP_GOOGLE: 'article_reactions_tap_google',
  ARTICLE_REACTIONS_TAP_DEFAULT: 'article_reactions_tap_default',
  ARTICLE_REACTIONS_TAP_MODAL: 'article_reactions_tap_modal',
  ARTICLE_REACTIONS_TAP_MODAL_BUTTONS: 'article_reactions_tap_modal_buttons',
  ARTICLE_REACTIONS_SUCCESS_GOOGLE: 'article_reactions_success_google',
  ARTICLE_REACTIONS_OBSERVE_GOOGLE: 'article_reactions_observe_google',
  ARTICLES_REACTIONS_CHANGE_WIDGET: 'article_reactions_change_widget',
  ARTICLES_REACTIONS_CATALOG_TAP: 'article_reactions_catalog_tap',
  ARTICLES_REACTIONS_CATALOG_OVERLAY_CLICK: 'article_reactions_catalog_overlay_click',
  COMMENTS_COUNT_CLICK: 'comments_count_click',
  SHARE_BUTTON_CLICK: 'share_button_click',
  SHARE_WINDOW_CLOSE_BY_ICON: 'share_window_close_by_icon',
  SHARE_WINDOW_CLOSE_BY_OVERLAY: 'share_window_close_by_overlay',
  SHARE_SOCIAL: 'share_social',
  SHARE_ARTICLE_BUTTON_CLICK: 'share_article_button_click',
  GROWTHBOOK_INIT: 'growthbook_init',
  ARTICLE_COMMENTS_OBSERVE: 'article_comments_observe',
  SHARE_TREND: 'share_trend',
  FOOTER_FEEDBACK_BUTTON_CLICK: 'footer_feedback_click',
  FOOTER_ADVERT_BUTTON_CLICK: 'footer_advert_click',
  ...PROFILE_EVENTS,
  ...LOGIN_EVENTS,
  ...SIGN_UP_EVENTS,
  ...RESTORE_PASSWORD_EVENTS,
  ...COMMENT_AUTH_OVERLAY,
  ...REQUEST_ERROR_EVENTS,
  ...ONE_SIGNAL_EMBED,
  ...DIRECT_LINKS_EVENTS,
  ...CUSTOM_EMBED_RELATED_CONTENT,
  ...MOBILE_AUTH_EVENTS,
  ...BOOKMARKS_EVENTS,
  ...PREGNANT_WIDGET_EVENTS,
  ...ADFOX_EVENTS
};

const NOTIFICATION_EVENTS = {
  SHOW: 'show-notification',
  HIDE: 'hide-notification'
};

export {
  ANALYTIC_EVENTS,
  NOTIFICATION_EVENTS
};
