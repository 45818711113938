const PROFILE_EVENTS = {
  HEADER_BUTTON_CLICK: 'HEADER_BUTTON_CLICK',
  PROFILE_BUTTON_CLICK: 'PROFILE_BUTTON_CLICK',
  PROFILE_LOGOUT_CLICK: 'PROFILE_LOGOUT_CLICK',
  // DELETE
  PROFILE_DELETE_BUTTON_CLICK: 'PROFILE_DELETE_BUTTON_CLICK',
  PROFILE_REASON_NEXT_CLICK: 'PROFILE_REASON_NEXT_CLICK',
  PROFILE_REASON_CHANGE: 'PROFILE_REASON_CHANGE',
  PROFILE_REASON_ERROR: 'PROFILE_REASON_ERROR',
  PROFILE_DELETE_REQUEST_SMS_CLICK: 'PROFILE_DELETE_REQUEST_SMS_CLICK',
  PROFILE_DELETE_SUBMIT_SMS_CLICK: 'PROFILE_DELETE_SUBMIT_SMS_CLICK',
  PROFILE_DELETE_SUBMIT_SUCCESS: 'PROFILE_DELETE_SUBMIT_SUCCESS',
  PROFILE_DELETE_GOOGLE_REQUEST_CLICK: 'PROFILE_DELETE_GOOGLE_REQUEST_CLICK',
  PROFILE_DELETE_CONFIRM_MODAL_SHOW: 'PROFILE_DELETE_CONFIRM_MODAL_SHOW',
  PROFILE_DELETE_CONFIRM_MODAL_CONFIRM: 'PROFILE_DELETE_CONFIRM_MODAL_CONFIRM',
  PROFILE_DELETE_SUCCESS_REDIRECT_CLICK: 'PROFILE_DELETE_SUCCESS_REDIRECT_CLICK',
  //   NICKNAME
  PROFILE_NICKNAME_EDIT_CLICK: 'PROFILE_NICKNAME_EDIT_CLICK',
  PROFILE_NICKNAME_SIDEBAR_EDIT_CLICK: 'PROFILE_NICKNAME_SIDEBAR_EDIT_CLICK',
  PROFILE_NICKNAME_EDIT_SUBMIT: 'PROFILE_NICKNAME_EDIT_SUBMIT',
  PROFILE_NICKNAME_ALREADY_EXISTS_ERROR: 'PROFILE_NICKNAME_ALREADY_EXISTS_ERROR',
  PROFILE_NICKNAME_EDIT_SUCCESS: 'PROFILE_NICKNAME_EDIT_SUCCESS',
  //   PHONE NUMBER
  PROFILE_PHONE_EDIT_CLICK: 'PROFILE_PHONE_EDIT_CLICK',
  PROFILE_PHONE_EDIT_CONTINUE_CLICK: 'PROFILE_PHONE_EDIT_CONTINUE_CLICK',
  PROFILE_PHONE_EDIT_NUMBER_ERROR: 'PROFILE_PHONE_EDIT_NUMBER_ERROR',
  PROFILE_PHONE_CONFIRM_CONTINUE_CLICK: 'PROFILE_PHONE_CONFIRM_CONTINUE_CLICK',
  PROFILE_PHONE_EDIT_ERROR: 'PROFILE_PHONE_EDIT_ERROR',
  PROFILE_PHONE_EDIT_SUCCESS: 'PROFILE_PHONE_EDIT_SUCCESS',
  //   BIRTH_DATE
  PROFILE_BIRTH_EDIT_CLICK: 'PROFILE_BIRTH_EDIT_CLICK',
  PROFILE_BIRTH_SAVE_CLICK: 'PROFILE_BIRTH_SAVE_CLICK',
  PROFILE_BIRTH_EDIT_ERROR: 'PROFILE_BIRTH_EDIT_ERROR',
  PROFILE_BIRTH_EDIT_SUCCESS: 'PROFILE_BIRTH_EDIT_SUCCESS',
  //   GENDER
  PROFILE_GENDER_EDIT_CLICK: 'PROFILE_GENDER_EDIT_CLICK',
  PROFILE_GENDER_SAVE_CLICK: 'PROFILE_GENDER_SAVE_CLICK',
  PROFILE_GENDER_EDIT_ERROR: 'PROFILE_GENDER_EDIT_ERROR',
  PROFILE_GENDER_EDIT_SUCCESS: 'PROFILE_GENDER_EDIT_SUCCESS',
  // PASSWORD
  PROFILE_PASSWORD_EDIT_CLICK: 'PROFILE_PASSWORD_EDIT_CLICK',
  PROFILE_PASSWORD_SMS_CONTINUE: 'PROFILE_PASSWORD_SMS_CONTINUE',
  PROFILE_PASSWORD_SAVE: 'PROFILE_PASSWORD_SAVE',
  PROFILE_PASSWORD_SMS_ERROR: 'PROFILE_PASSWORD_SMS_ERROR',
  PROFILE_PASSWORD_ERROR: 'PROFILE_PASSWORD_ERROR',
  PROFILE_PASSWORD_SUCCESS: 'PROFILE_PASSWORD_SUCCESS',
  PROFILE_EDIT_COMMON_ERROR: 'PROFILE_EDIT_COMMON_ERROR',
  // GOOGLE_LINK
  PROFILE_GOOGLE_LINK_CLICK: 'PROFILE_GOOGLE_LINK_CLICK',
  PROFILE_GOOGLE_LINK_SUCCESS: 'PROFILE_GOOGLE_LINK_SUCCESS',
  PROFILE_GOOGLE_LINK_ERROR_REGISTERED: 'PROFILE_GOOGLE_LINK_ERROR_REGISTERED',
  PROFILE_GOOGLE_LINK_ERROR_EXIST: 'PROFILE_GOOGLE_LINK_ERROR_EXIST'
};

export {PROFILE_EVENTS};
