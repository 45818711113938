import React, {
  useCallback,
  useEffect
} from 'react';

import {Notification} from '@nur-portal/ui-kit';

import {NOTIFICATION_DURATION} from './helpers';

import styles from './styles.module.css';

const NotificationWrapper = ({
  hideNotification,
  id,
  ...props
}) => {
  const handleClose = useCallback(
    () => hideNotification(id),
    [id, hideNotification]
  );

  useEffect(() => {
    setTimeout(
      handleClose,
      NOTIFICATION_DURATION
    );
  }, []);

  return (
    <Notification
      {...props}
      onClose={handleClose}
      wrapperClassName={styles.notificationRow}
    />
  );
};

export default NotificationWrapper;
