const COMMENT_AUTH_OVERLAY = {
  WIDGET_COLLAPSE_TOGGLE: 'WIDGET_COLLAPSE_TOGGLE',
  WIDGET_NOT_NOW_CLICK: 'WIDGET_NOT_NOW_CLICK',
  WIDGET_SET_PROFILE_INFO_CLICK: 'WIDGET_SET_PROFILE_INFO_CLICK',
  WIDGET_SET_PROFILE_INFO_NO_PHONE_CLICK: 'WIDGET_SET_PROFILE_INFO_NO_PHONE_CLICK',
  WIDGET_SET_PROFILE_INFO_NO_NICKNAME_CLICK: 'WIDGET_SET_PROFILE_INFO_NO_NICKNAME_CLICK',
  WIDGET_COLLAPSED_LINK_CLICK: 'WIDGET_COLLAPSED_LINK_CLICK',
  WIDGET_EXPANDED_LINK_CLICK: 'WIDGET_EXPANDED_LINK_CLICK',
  WIDGET_EXPANDED_LINK_GROUP_B_CLICK: 'WIDGET_EXPANDED_LINK_GROUP_B_CLICK',
  // widget phone and nickname
  WIDGET_ALL_PHONE_SUBMIT_CLICK: 'WIDGET_ALL_PHONE_SUBMIT_CLICK',
  WIDGET_ALL_PHONE_CANCEL_CLICK: 'WIDGET_ALL_PHONE_CANCEL_CLICK',
  WIDGET_ALL_SMS_CODE_SUBMIT_CLICK: 'WIDGET_ALL_SMS_CODE_SUBMIT_CLICK',
  WIDGET_ALL_PHONE_SUCCESS: 'WIDGET_ALL_PHONE_SUCCESS',
  WIDGET_ALL_NICKNAME_SUBMIT_CLICK: 'WIDGET_ALL_NICKNAME_SUBMIT_CLICK',
  WIDGET_ALL_NICKNAME_CANCEL_CLICK: 'WIDGET_ALL_NICKNAME_CANCEL_CLICK',
  WIDGET_ALL_NICKNAME_SUCCESS: 'WIDGET_ALL_NICKNAME_SUCCESS',
  WIDGET_ALL_PHONE_PROVIDER_ERROR: 'WIDGET_ALL_PHONE_PROVIDER_ERROR',
  WIDGET_ALL_PHONE_LINKED_ERROR: 'WIDGET_ALL_PHONE_LINKED_ERROR',
  // widget phone
  WIDGET_PHONE_SUBMIT_CLICK: 'WIDGET_PHONE_SUBMIT_CLICK',
  WIDGET_PHONE_CANCEL_CLICK: 'WIDGET_PHONE_CANCEL_CLICK',
  WIDGET_PHONE_SMS_SUBMIT_CLICK: 'WIDGET_PHONE_SMS_SUBMIT_CLICK',
  WIDGET_PHONE_SUCCESS: 'WIDGET_PHONE_SUCCESS',
  WIDGET_PHONE_PROVIDER_ERROR: 'WIDGET_PHONE_PROVIDER_ERROR',
  WIDGET_PHONE_LINKED_ERROR: 'WIDGET_PHONE_LINKED_ERROR',
  //   widget nickname
  WIDGET_NICKNAME_SUBMIT_CLICK: 'WIDGET_NICKNAME_SUBMIT_CLICK',
  WIDGET_NICKNAME_CANCEL_CLICK: 'WIDGET_NICKNAME_CANCEL_CLICK',
  WIDGET_NICKNAME_SUCCESS: 'WIDGET_NICKNAME_SUCCESS'
};

export {COMMENT_AUTH_OVERLAY};
